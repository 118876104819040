import React from 'react'
import Header from './Header'
import LatestNews from './LatestNews'
import Footer from './Footer'
import SelectSite from './SelectSite'

const Home = () => {
    return (
        <>
            <header>
                <Header />
            </header>
            <div className='main-content'>
                <SelectSite />
                <LatestNews />
            </div>
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default Home