import React, { useState } from 'react'
import { useNavigate } from 'react-router';

const Login = () => {


    const [showPassword, setShowPassword] = useState(true);

    const navigate = useNavigate()


    const handleHome = () => {
        navigate('/sites')
    }


    return (
        <div className='log_sec'>
            <div className='log_ban'>
                <img src='images/login_image.jpg' alt='banner' />
            </div>
            <div className='log_parent'>
                <div className='log_blk'>
                    <div className='login' onClick={handleHome}>
                        <img src='images/login_img_white.png' alt='logo' />
                    </div>
                    <div className='login_blk'>
                        <h1>
                            Welkom
                        </h1>
                        <p className='login_name'>
                            Login op uw account
                        </p>

                        <div className='log_input'>
                            <label>
                                <input type='text' name='email' defaultValue="hendrikjan@rohr-idreco.com" placeholder='Enter Your Email' />
                            </label>
                            <label className='password' >
                                <input type={showPassword ? "text" : "password"} placeholder='Enter Your Password' name='password' defaultValue="Wachtwoord" />
                                {showPassword ? (
                                    <img src='images/eyeopen.svg' alt='show' onClick={() => setShowPassword(!showPassword)} />) : (

                                    <img src='images/eyeclose.svg' alt='hide' onClick={() => setShowPassword(!showPassword)} />

                                )}
                            </label>
                        </div>
                        <div className='for_pass'>
                            <label>
                                <input type='checkbox' />
                                <span>
                                    Onthoud mij
                                </span>
                            </label>
                            <a href='Forgotpassword'>
                                Wachtwoord vergeten?
                            </a>
                        </div>
                        <p className="log_btn">Inloggen</p>
                        <div className='line'>
                        </div>
                        <div className="bottom_buttons">
                            <div className='btn_flex'>
                                <p className="logbut1">PRODUCT LIJN</p>
                                <p className="logbut2">Service</p>
                            </div>
                            <p className="logbut3">ONDERDELEN BESTELLEN</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login