import React from 'react'
import Header from './Header'
import Select from 'react-select'
import Footer from './Footer'
import { useNavigate } from 'react-router'
// import Select from "react-dropdown-select";


const Profile = () => {

    const navigate = useNavigate()

    const options = [
        { value: 'Netherlands', label: 'NED' },
        { value: 'United States', label: 'USA' },
        { value: 'Europe', label: 'EUR' },
    ]

    const defaultOption = options.find(option => option.value === 'Netherlands');

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            <header>
                <Header />
            </header>
            <div className='prosection'>
                <div className='container'>
                    <div className='proheading'>
                        <img src="images/bkbtn.svg" alt="Back" onClick={handleBack} />

                        <p>
                            Profiel wijzigen
                        </p>
                    </div>
                    <div className='prosec'>
                        <div className='problk'>

                            <div className='lftblk'>
                                <img src='images/profilephoto.png' alt='profile' />
                                <label className='imageedit'>
                                    <img src='images/proedit.svg' alt='edit' />
                                    <input type='file' />
                                </label>
                            </div>
                            <div className='rgtblk'>
                                <div className='pro1'>
                                    <label>
                                        <input type='text' placeholder='Name' name='name' defaultValue="Hendrik Jan Kort" />
                                    </label>
                                    <div className='counopt'>
                                        <Select options={options} defaultValue={defaultOption} />
                                    </div>
                                </div>
                                <div className='pro2'>
                                    <label>
                                        <input type='email' placeholder='Email' name='email' defaultValue="hendrikjan@gmail.com" />
                                    </label>
                                    <label>
                                        <input type='phonenumber' placeholder='Phone Number' name='phonenumber' defaultValue="+31 6 123 456 78" />
                                    </label>
                                </div>
                                <div className='pro3'>
                                    <input type='text' placeholder='Address' name='address' defaultValue="Sraatnaam 123, 1234 AA Plaats (Nederland)" />
                                </div>
                                <b>Toegewezen sites</b>
                                <div className='prosites'>
                                    <Select options={options} defaultInputValue='Site / location naam 1' />
                                    <button>Contact </button>
                                </div>
                                <div className='prosites'>
                                    <Select options={options} defaultInputValue='Site / location naam 2' />
                                    <button>Contact </button>
                                </div>
                                <p className='probutton'>
                                    Profiel wijzigen
                                </p>
                                <div className='probut'>
                                    <p className='probut1'>
                                        Profiel verwijderen
                                    </p>
                                    <p className='probut2'>
                                        Uitloggen
                                    </p>
                                </div>
                                <div className='pri_pol'>
                                    <a className='pri_pol1' href='pri'>
                                        Algemene voorwaarden
                                    </a>
                                    <span>
                                        /
                                    </span>
                                    <a className='pri_pol2' href='pri'>
                                        Privacy policy links
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default Profile