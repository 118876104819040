import React from 'react'

const LatestNews = () => {
    return (
        <div className='latnewssec'>
            <div className='container'>
                <div className='latnewsblk'>
                    <p className='latnewstxt'>Laatste nieuws</p>
                    <div className='newsitemblk'>
                        <div className='newsitem'>
                            <div className='lftblk'>
                                <div className='imgblk'>
                                    <img src='images/latnews.svg' alt='alert' />
                                    <span className='dot'></span>
                                </div>
                            </div>
                            <div className='rgtblk'>
                                <p>
                                    Curabitur vel nulla augue
                                </p>
                                <span>
                                    27/02/2024
                                </span>
                            </div>
                        </div>
                        <div className='newsitem'>
                            <div className='lftblk'>
                                <div className='imgblk'>
                                    <img src='images/latnews.svg' alt='alert' />
                                    <span className='dot'></span>
                                </div>
                            </div>
                            <div className='rgtblk'>
                                <p>
                                    Mauris nec risus var malesuada ligula eget
                                </p>
                                <span>
                                    27/02/2024
                                </span>
                            </div>
                        </div>
                        <div className='newsitem'>
                            <div className='lftblk'>
                                <div className='imgblk'>
                                    <img src='images/latnews.svg' alt='alert' />
                                    {/* <span className='dot'></span> */}
                                </div>
                            </div>
                            <div className='rgtblk'>
                                <p>
                                    Vivamus finibus metus quis
                                </p>
                                <span>
                                    27/02/2024
                                </span>
                            </div>
                        </div>
                        <div className='newsitem'>
                            <div className='lftblk'>
                                <div className='imgblk'>
                                    <img src='images/latnews.svg' alt='alert' />
                                    {/* <span className='dot'></span> */}
                                </div>
                            </div>
                            <div className='rgtblk'>
                                <p>
                                    Aenean dictum nisi non sodales lobortis
                                </p>
                                <span>
                                    27/02/2024
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestNews