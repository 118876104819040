import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router'

const SelectUnit = () => {

    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            <header>
                <Header />
            </header>
            <div className='unitsec'>
                <div className='container'>
                    <div className='unitblk'>
                        <div className='unittop'>
                            <div className='unitheading'>
                                <img src='images/bkbtn.svg' alt='Back' onClick={handleBack} />
                                <p>
                                    Site / location naam 1
                                </p>
                            </div>
                        </div>
                        <p className='unitsel'>
                            Unit selectie
                        </p>
                        <div className='unititemblk'>
                            <div className='unititems'>
                                <div className='unititem'>
                                    <div className='lft'>
                                        <p>Cutter dredge falcon 1</p>
                                    </div>
                                    <div className='rgt'>
                                        <span>Status</span>
                                        <span className="status green"></span>
                                    </div>
                                </div>
                                <div className='emptyspace'></div>
                            </div>

                            <div className='unititems'>
                                <div className='unititem'>
                                    <div className='lft'>
                                        <p>Cutter dredge falcon 2</p>
                                    </div>
                                    <div className='rgt'>
                                        <span>Status</span>
                                        <span className="status red"></span>
                                    </div>
                                </div>
                                <div className='emptyspace'></div>
                            </div>

                            <div className='unititems'>
                                <div className='unititem'>
                                    <div className='lft'>
                                        <p>Cutter dredge falcon 3</p>
                                    </div>
                                    <div className='rgt'>
                                        <span>Status</span>
                                        <span className="status yellow"></span>
                                    </div>
                                </div>
                                <div className='emptyspace'></div>
                            </div>

                            <div className='unititems'>
                                <div className='unititem'>
                                    <div className='lft'>
                                        <p>Booster 1</p>
                                    </div>
                                    <div className='rgt'>
                                        <span>Status</span>
                                        <span className="status green"></span>
                                    </div>
                                </div>
                                <div className='emptyspace'></div>
                            </div>

                            <div className='unititems'>
                                <div className='unititem'>
                                    <div className='lft'>
                                        <p>Booster 2</p>
                                    </div>
                                    <div className='rgt'>
                                        <span>Status</span>
                                        <span className="status yellow"></span>
                                    </div>
                                </div>
                                <div className='emptyspace'></div>
                            </div>
                        </div>
                        <div className='statusblk'>
                            <ul>
                                <li>
                                    <div className='statusitem'>
                                        <span className='staclr green'></span>
                                    </div>
                                    <p>Online</p>
                                </li>
                                <li>
                                    <div className='statusitem'>
                                        <span className='staclr red'></span>
                                    </div>
                                    <p>Offline</p>
                                </li>
                                <li>
                                    <div className='statusitem'>
                                        <span className='staclr yellow'></span>
                                    </div>
                                    <p>StandBy</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default SelectUnit