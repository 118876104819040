import React from 'react'

const SelectSite = () => {
  return (
    <>
      <div className='sitelocblk'>
        <div className='container'>
          <p className='siteloctxt'>
            Select your site/location
          </p>
          <div className='siteblk'>
            <div className='sitelocitem'>
              <div className='lftblk'>
                <img src='images/site_image1.png' alt='siteimage' />

              </div>
              <div className='rgtblk'>
                <div className='rgtitem1'>
                  <p>
                    Naam van de locatie
                  </p>
                  <div className='dotblk'>
                    <img className="imagedot" src='images/message.svg' alt='message' />
                    <span className='dot'></span>
                  </div>
                  {/* <img src='images/message.svg' alt='message' /> */}
                </div>
                <div className='rgtitem2'>
                  <img src='images/location.svg' alt='location' />
                  <p> Straat 123, 1234 AA Plaats</p>
                </div>
                <div className='rgtitem3'>
                  <img src='images/message.svg' alt='message' />
                  <p>0541 12 34 56 </p>
                </div>
              </div>
            </div>

            <div className='sitelocitem'>
              <div className='lftblk'>
                <img src='images/site_image2.png' alt='siteimage' />

              </div>
              <div className='rgtblk'>
                <div className='rgtitem1'>
                  <p>
                    Naam van de locatie
                  </p>
                  <div className='dotblk'>
                    <a href='noti'>

                      <img className="imagedot" src='images/message.svg' alt='message' />
                      {/* <span className='dot'></span> */}
                    </a>

                  </div>

                </div>
                <div className='rgtitem2'>
                  <img src='images/location.svg' alt='location' />
                  <p> Straat 123, 1234 AA Plaats</p>
                </div>
                <div className='rgtitem3'>
                  <img src='images/message.svg' alt='message' />
                  <p>0541 12 34 56 </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default SelectSite