import React from 'react'
import { useLocation, useNavigate } from 'react-router';

const Header = () => {

    const location = useLocation();
    const isSitesActive = location.pathname.includes('/sites');
    const isServiceActive = location.pathname.includes('/units');
    const isProfileActive = location.pathname.includes('/profile');

    const navigate = useNavigate()

    const handleHome = () => {
        navigate('/sites')
    }

    return (
        <>
            <div className='header'>
                <div className='container'>
                    <div className='headblk'>
                        <div className='headlft'>
                            <div className='logo' onClick={handleHome}>
                                <img src='images/rohr_logo.svg' alt='logo' />
                            </div>
                        </div>
                        <div className='headrgt'>
                            <ul>
                                <li className={isSitesActive ? 'active' : ''}>
                                    <a href='sites'>
                                        Home
                                    </a>
                                </li>
                                <li className={isServiceActive ? 'active' : ''}>
                                    <a href='units'>
                                        Service
                                    </a>
                                </li>
                                <li className={isProfileActive ? 'active' : ''}>
                                    <a href='profile'>
                                        Profile
                                    </a>
                                </li>
                            </ul>
                            <div className='noti'>
                                <a href='noti'>
                                    <img src='images/bell.svg' alt='notifications' />
                                    <span className='notinum'>2</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Header