import React from 'react'
import { useLocation, useNavigate } from 'react-router';

const Footer = () => {

    const navigate = useNavigate()

    const location = useLocation();
    const isSitesActive = location.pathname.includes('/sites');
    const isServiceActive = location.pathname.includes('/units');
    const isProfileActive = location.pathname.includes('/profile');

    const handleSites = () => {
        navigate('/sites')
    }

    const handleService = () => {
        navigate('/units')
    }

    const handleProfile = () => {
        navigate('/profile')
    }

    return (
        <div className='footer'>
            <div className='header'>
                <div className='container'>
                    <div className='headblk'>
                        <div className='headrgt'>
                            <ul>
                                <li className={isSitesActive ? 'active' : ''} onClick={handleSites}>
                                    <img src={isSitesActive ? 'images/home_2.svg' : 'images/home_1.svg'} alt='Home' />
                                    <a href='sites'>
                                        Home
                                    </a>
                                </li>
                                <li className={isServiceActive ? 'active' : ''} onClick={handleService}>
                                    <img src={isServiceActive ? 'images/service_2.svg' : 'images/service_1.svg'} alt='Service' />
                                    <a href='units'>
                                        Service
                                    </a>
                                </li>
                                <li className={isProfileActive ? 'active' : ''} onClick={handleProfile}>
                                    <img src={isProfileActive ? 'images/profile_2.svg' : 'images/profile_1.svg'} alt='Profile' />
                                    <a href='profile'>
                                        Profile
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Footer