import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router'

const TextPage = () => {

    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            <header>
                <Header />

            </header>
            <div className='txtsec'>
                <div className='container'>
                    <div className='txtpage'>
                        <div className="txtheading">
                        <img src="images/bkbtn.svg" alt="Back" onClick={handleBack} />

                        <p>Tekstuele Pagina</p>
                        </div>
                        <div className='txtblk1'>
                            <div className='txtflex'>
                                <div className='txtlft'>
                                    <img src='images/txtpage1.png' alt='Privacy Policy' />
                                </div>
                                <div className='txtrgt'>
                                    <b>
                                        Praesent eu neque id erat venenatis
                                    </b>
                                    <p>
                                        Proin egestas sem vel orci ultricies elementum. Pellentesque tempor consequat arcu et dignissim. Sed hendrerit dui fringilla, efficitur sapien eget, tempus est. In ut cursus urna. Fusce bi bendum, turpis ac mollis aliquam, tellus urna malesuada odio, et pulvinar nisi ipsum at nibh. Aliquam diam tellus, auctor sit amet sapien eu, pellentesque semper ex.

                                    </p>
                                    <p>
                                        Morbi vulputate et odio eget eleifend. In quis arcu vel est auctor dignissim vel non dolor.Proin egestas sem vel orci ultricies elementum. Pellentesque tempor consequat arcu et dignissim.

                                    </p>
                                    <p>
                                        Phasellus laoreet accumsan vulputate. Pellentesque dignissim sem risus, feugiat pellentesque ante porttitor eget. Etiam et interdum quam. Duis tempor lacus ac turpis molestie finibus. Pellentesque consequat diam ut libero rutrum tincidunt. Praesent vel dignissim tellus.
                                    </p>
                                </div>
                            </div>
                            <p className='textcont'>
                                Pellentesque commodo odio a tortor tempor luctus. Morbi dapibus justo quam, eget ultrices neque blandit quis. In lacus sem, elementum sit amet est sit amet, tincidunt auctor mi.
                            </p>
                        </div>
                        <div className='txtblk2'>
                            <div className='imgblk'>
                                <img src='images/txtpage2.png' alt='privacy' />
                                <img src='images/txtpage3.png' alt='privacy' />
                                <img src='images/txtpage4.png' alt='privacy' />
                            </div>
                            <b>
                                Praesent eu neque id erat venenatis
                            </b>
                            <ul>
                                <li>
                                    Proin egestas sem vel orci ultricies elementum.
                                </li>
                                <li>
                                    Pellentesque tempor consequat arcu et dignissim.    </li>
                                <li>
                                    Class aptent taciti sociosqu ad litora torquent     </li>
                                <li>
                                    Fusce ac est eget nisi volutpat lacinia. Fusce facilisis nulla ut sem egestas congue. Integer id blandit magna. Proin rhon cus
                                    eros placerat felis euismod sagittis.    </li>
                            </ul>
                            <p >
                                Proin egestas sem vel orci ultricies elementum. Pellentesque tempor consequat arcu et dignis sim. Sed hendrerit dui fringilla efficitur.Phasellus laoreet accumsan vulputate. Pellentesque dignissim sem risus, feugiat pellentesque ante porttitor eget. Etiam et interdum quam. Duis tempor lacus ac turpis molestie finibus.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default TextPage