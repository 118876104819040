import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from 'react-router'

const SelectPage = () => {

    const navigate = useNavigate()


    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            <header>
                <Header />
            </header>
            <div className='pagesection'>
                <div className='container'>
                    <div className='pageheading'>
                        <img src="images/bkbtn.svg" alt="Back" onClick={handleBack} />
                        <p>
                            Cutter dredge falcon 1
                        </p>
                    </div>
                    <div className='pageblk'>
                        <div className='pagehead'>
                            <p>
                                Pagina selectie
                            </p>
                            <div className='statusblk'>
                                <p>
                                    Unit Status
                                </p>
                                <div className='stablk'>
                                    <span className="status green"></span>
                                    <p>Online </p>
                                </div>
                            </div>
                        </div>

                        <div className='pageitemblk'>
                            {/* <div className='pageitem'>
                                <p>
                                    Overzicht
                                </p>
                            </div>
                            <div className='pageitem'>
                                <p>
                                    Productie
                                </p>
                            </div>
                            <div className='pageitem'>
                                <p>
                                    alamering
                                </p>
                            </div>
                            <div className='pageitem'>
                                <p>
                                    Reviews
                                </p>
                            </div> */}

                            <p>
                                Overzicht
                            </p>
                            <p>
                                Productie
                            </p>
                            <p>
                                alamering
                            </p>
                            <p>
                                Reviews
                            </p>

                        </div>

                    </div>
                </div>
            </div >
            <footer>
                <Footer />
            </footer>
        </>
    )
}

export default SelectPage