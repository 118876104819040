import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Home from './components/Home';
import SelectUnit from './components/SelectUnit';
import SelectPage from './components/SelectPage';
import Profile from './components/Profile';
import Login from './components/Login';
import TextPage from './components/TextPage';

function App() {
  return (
    <>
      <BrowserRouter basename="">
        <Routes>
          <Route index element={<Navigate to="/sites" replace />} />
          <Route path="/sites" element={<Home />} />
          <Route path="/units" element={<SelectUnit />} />
          <Route path="/pages" element={<SelectPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/text" element={<TextPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
